.containerFluid {
    background-color: #f1f1f1;
    text-align: center;
}

.img {
    margin-top: 40px;
}

.heading {
    padding-top: 50px;
    text-transform: uppercase;
    font-family: var(--font-primary);
    font-size: 36px;
    font-weight: 900;
    font-stretch: normal;
    font-style: italic;
    color: rgba(51, 51, 51, 0.85);
}

.aboutUsPara {
    margin-bottom: 10px;
}

.paraContainerMobile {
    display: none;
}

.paraContainerPC {
    display: block;
}

.paraContainer {
    padding-top: 10px;
    padding-bottom: 50px;
}

@media screen and (max-width: 1024px) {
    .container {
        height: auto;
        padding: 20px
    }

    .heading {
        padding-top: 20px;
        font-size: 30px;
    }


    .aboutUsPara {
        font-size: 16px;
    }

    .paraContainer {
        padding-bottom: 20px;
    }

}

@media screen and (max-width: 767px) {
    .container {
        margin-top: 0;
        height: auto;
        padding: 15px
    }

    .heading {
        padding-top: 20px;
        font-size: 25px;
    }

    .aboutUsPara {
        font-size: 14px;
    }

    .paraContainerMobile {
        display: block;
    }

    .paraContainerPC {
        display: none;
    }

    .paraContainer {
        padding-bottom: 20px;
    }

}

@media screen and (max-width: 576px) {
    .container {
        height: auto;
        padding: 15px
    }

    .heading {
        padding-top: 20px;
        font-size: 18px;
    }

    .aboutUsPara {
        font-size: 12px;
    }

    .paraContainer {
        padding-bottom: 20px;
    }
}